import leavingAMarkInTheMarvelUniverseThumbnail from '../../images/fall-winter-2021/menu-thumbnails/leaving-a-mark-in-the-marvel-universe.jpg';
import fightingInfernosBeforeTheyStartThumbnail from '../../images/fall-winter-2021/menu-thumbnails/fighting-infernos-before-they-start.jpg';
import AtaleOfTwoOlympicVictoriesThumbnail from '../../images/fall-winter-2021/menu-thumbnails/a-tale-of-two-olympic-victories.jpg';
import uncoveringMemoriesFromWorldWarIiThumbnail from '../../images/fall-winter-2021/menu-thumbnails/uncovering-memories-from-world-war-ii.jpg';
import ProjectsTransformingTheCampusThumbnail from '../../images/fall-winter-2021/menu-thumbnails/9-projects-transforming-the-campus.jpg';
import presidentSMessageThumbnail from '../../images/fall-winter-2021/menu-thumbnails/presidents-message.jpg';

import leavingAMarkInTheMarvelUniverseSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/leaving-a-mark-in-the-marvel-universe.jpg';
import fightingInfernosBeforeTheyStartSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/fighting-infernos-before-they-start.jpg';
import AtaleOfTwoOlympicVictoriesSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/a-tale-of-two-olympic-victories.jpg';
import uncoveringMemoriesFromWorldWarIiSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/uncovering-memories-from-world-war-ii.jpg';
import ProjectsTransformingTheCampusSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/9-projects-transforming-the-campus.jpg';
import presidentSMessageSmallThumbnail from '../../images/fall-winter-2021/small-thumbnails/presidents-message.jpg';

import leavingAMarkInTheMarvelUniverseIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/leaving-a-mark-in-the-marvel-universe.jpg';
import fightingInfernosBeforeTheyStartIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/fighting-infernos-before-they-start.jpg';
import AtaleOfTwoOlympicVictoriesIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/a-tale-of-two-olympic-victories.jpg';
import uncoveringMemoriesFromWorldWarIiIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/uncovering-memories-from-world-war-ii.jpg';
import ProjectsTransformingTheCampusIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/9-projects-transforming-the-campus.jpg';
import presidentSMessageIndexThumbnail from '../../images/fall-winter-2021/index-thumbnails/presidents-message.jpg';

import leavingAMarkInTheMarvelUniverseSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/leaving-a-mark-in-the-marvel-universe.jpg';
import fightingInfernosBeforeTheyStartSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/fighting-infernos-before-they-start.jpg';
import AtaleOfTwoOlympicVictoriesSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/a-tale-of-two-olympic-victories.jpg';
import uncoveringMemoriesFromWorldWarIiSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/uncovering-memories-from-world-war-ii.jpg';
import ProjectsTransformingTheCampusSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/9-projects-transforming-the-campus.jpg';
import presidentSMessageSmallRectThumbnail from '../../images/fall-winter-2021/small-thumbnails-rect/presidents-message.jpg';




export const fallWinter2021stories = [
  {
    // Marvel
    slug: 'leaving-a-mark-in-the-marvel-universe',
    title: 'Leaving a Mark in the Marvel Universe',
    subtitle: 'An illustration alumnus lands his dream job with Marvel Themed Entertainment.',
    storyType: 'feature',
    thumbnail: leavingAMarkInTheMarvelUniverseThumbnail,
    thumbnailSmall: leavingAMarkInTheMarvelUniverseSmallThumbnail,
    thumbnailSmallRect: leavingAMarkInTheMarvelUniverseSmallRectThumbnail,
    thumbnailIndex: leavingAMarkInTheMarvelUniverseIndexThumbnail,
  },
  {
    // Wildfires
    slug: 'fighting-infernos-before-they-start',
    title: 'Fighting Infernos Before They Start',
    subtitle: 'A faculty and student engineering team is using artificial intelligence to save property — and lives.',
    storyType: 'feature',
    thumbnail: fightingInfernosBeforeTheyStartThumbnail,
    thumbnailSmall: fightingInfernosBeforeTheyStartSmallThumbnail,
    thumbnailSmallRect: fightingInfernosBeforeTheyStartSmallRectThumbnail,
    thumbnailIndex: fightingInfernosBeforeTheyStartIndexThumbnail,
  },
  {
    // Olympics
    slug: 'a-tale-of-two-olympic-victories',
    title: 'A Tale of Two Olympic Victories',
    subtitle: 'Titans reflect on their historic gold medal wins at the Tokyo Olympics.',
    storyType: 'feature',
    thumbnail: AtaleOfTwoOlympicVictoriesThumbnail,
    thumbnailSmall: AtaleOfTwoOlympicVictoriesSmallThumbnail,
    thumbnailSmallRect: AtaleOfTwoOlympicVictoriesSmallRectThumbnail,
    thumbnailIndex: AtaleOfTwoOlympicVictoriesIndexThumbnail,
  },
  {
    // COPH
    slug: 'uncovering-memories-from-world-war-ii',
    title: 'Uncovering Memories From World War II',
    subtitle: 'A history alumna chronicles Japanese American experiences for future generations.',
    storyType: 'feature',
    thumbnail: uncoveringMemoriesFromWorldWarIiThumbnail,
    thumbnailSmall: uncoveringMemoriesFromWorldWarIiSmallThumbnail,
    thumbnailSmallRect: uncoveringMemoriesFromWorldWarIiSmallRectThumbnail,
    thumbnailIndex: uncoveringMemoriesFromWorldWarIiIndexThumbnail,
  },
  {
    // Campus Construction
    slug: '9-projects-transforming-the-campus',
    title: '9 Projects Transforming the Campus',
    subtitle: 'Advancing the learning environment prepares Titans for a rapidly changing world.',
    storyType: 'feature',
    thumbnail: ProjectsTransformingTheCampusThumbnail,
    thumbnailSmall: ProjectsTransformingTheCampusSmallThumbnail,
    thumbnailSmallRect: ProjectsTransformingTheCampusSmallRectThumbnail,
    thumbnailIndex: ProjectsTransformingTheCampusIndexThumbnail,
  },
  {
    // President’s Message
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: presidentSMessageThumbnail,
    thumbnailSmall: presidentSMessageSmallThumbnail,
    thumbnailSmallRect: presidentSMessageSmallRectThumbnail,
    thumbnailIndex: presidentSMessageIndexThumbnail,
  }
];