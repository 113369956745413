import bringingSoldiersHome from '../../images/fall-winter-2020/menu-thumbnails/bringing-soldiers-home.jpg';
import antiRacistTeaching from '../../images/fall-winter-2020/menu-thumbnails/anti-racist-teaching.jpg';
import aVirusHitsHome from '../../images/fall-winter-2020/menu-thumbnails/a-virus-hits-home.jpg';
import shiftingGearsToDrive from '../../images/fall-winter-2020/menu-thumbnails/shifting-gears-to-drive.jpg';
import becomingChristmasKaren from '../../images/fall-winter-2020/menu-thumbnails/becoming-christmas-karen.jpg';
import presidentsMessage from '../../images/fall-winter-2020/menu-thumbnails/presidents-message.jpg';

import bringingSoldiersHomeSmall from '../../images/fall-winter-2020/small-thumbnails/bringing-soldiers-home.jpg';
import antiRacistTeachingSmall from '../../images/fall-winter-2020/small-thumbnails/anti-racist-teaching.jpg';
import aVirusHitsHomeSmall from '../../images/fall-winter-2020/small-thumbnails/a-virus-hits-home.jpg';
import shiftingGearsToDriveSmall from '../../images/fall-winter-2020/small-thumbnails/shifting-gears-to-drive.jpg';
import becomingChristmasKarenSmall from '../../images/fall-winter-2020/small-thumbnails/becoming-christmas-karen.jpg';
import presidentsMessageSmall from '../../images/fall-winter-2020/small-thumbnails/presidents-message.jpg';

import bringingSoldiersHomeIndex from '../../images/fall-winter-2020/index-thumbnails/bringing-soldiers-home.jpg';
import antiRacistTeachingIndex from '../../images/fall-winter-2020/index-thumbnails/anti-racist-teaching.jpg';
import aVirusHitsHomeIndex from '../../images/fall-winter-2020/index-thumbnails/a-virus-hits-home.jpg';
import shiftingGearsToDriveIndex from '../../images/fall-winter-2020/index-thumbnails/shifting-gears-to-drive.jpg';
import becomingChristmasKarenIndex from '../../images/fall-winter-2020/index-thumbnails/becoming-christmas-karen.jpg';
import presidentsMessageIndex from '../../images/fall-winter-2020/index-thumbnails/presidents-message.jpg';

import bringingSoldiersHomeSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/bringing-soldiers-home.jpg';
import antiRacistTeachingSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/anti-racist-teaching.jpg';
import aVirusHitsHomeSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/a-virus-hits-home.jpg';
import shiftingGearsToDriveSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/shifting-gears-to-drive.jpg';
import becomingChristmasKarenSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/becoming-christmas-karen.jpg';
import presidentsMessageSmallRect from '../../images/fall-winter-2020/small-thumbnails-rect/presidents-message.jpg';

export const fall2020stories = [
  {
    slug: 'a-virus-hits-home',
    title: 'A Virus Hits Home',
    subtitle: 'She recovered from COVID-19, but her grandmother didn’t. Becoming a nurse now carries new meaning.',
    storyType: 'feature',
    thumbnail: aVirusHitsHome,
    thumbnailSmall: aVirusHitsHomeSmall,
    thumbnailSmallRect: aVirusHitsHomeSmallRect,
    thumbnailIndex: aVirusHitsHomeIndex,
  },
  {
    slug: 'bringing-soldiers-home',
    title: 'Bringing Soldiers Home',
    subtitle: 'A historian searches for evidence and memories of America’s missing personnel.',
    storyType: 'feature',
    thumbnail: bringingSoldiersHome,
    thumbnailSmall: bringingSoldiersHomeSmall,
    thumbnailSmallRect: bringingSoldiersHomeSmallRect,
    thumbnailIndex: bringingSoldiersHomeIndex,
  },
  {
    slug: 'anti-racist-teaching',
    title: 'Lessons on Anti-Racist Teaching',
    subtitle: 'College of Education faculty share stories of dismantling racism and injustice in the classroom.',
    storyType: 'feature',
    thumbnail: antiRacistTeaching,
    thumbnailSmall: antiRacistTeachingSmall,
    thumbnailSmallRect: antiRacistTeachingSmallRect,
    thumbnailIndex: antiRacistTeachingIndex,
  },
  {
    slug: 'shifting-gears-to-drive',
    title: 'Shifting Gears to Drive',
    subtitle: 'After a spinal cord injury, a mechanical engineering grad invents an innovative manual driving system.',
    storyType: 'feature',
    thumbnail: shiftingGearsToDrive,
    thumbnailSmall: shiftingGearsToDriveSmall,
    thumbnailSmallRect: shiftingGearsToDriveSmallRect,
    thumbnailIndex: shiftingGearsToDriveIndex,
  },
  {
    slug: 'becoming-christmas-karen',
    title: 'Becoming ‘Christmas Karen’',
    subtitle: 'During tough times, a Titan alumna spreads hope through feel-good holiday stories.',
    storyType: 'feature',
    thumbnail: becomingChristmasKaren,
    thumbnailSmall: becomingChristmasKarenSmall,
    thumbnailSmallRect: becomingChristmasKarenSmallRect,
    thumbnailIndex: becomingChristmasKarenIndex,
  },
  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: presidentsMessage,
    thumbnailSmall: presidentsMessageSmall,
    thumbnailSmallRect: presidentsMessageSmallRect,
    thumbnailIndex: presidentsMessageIndex,
  }
];