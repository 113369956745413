// import exampleStory_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/example-story.jpg';
// import exampleStory_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/example-story.jpg';

import AFamilyofTitanScholars_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/a-family-of-titan-scholars-legacy-menu-thumbnails-400x400.jpg';
import AHomeBehindtheLens_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/a-home-behind-the-lens-menu-thumbnails-400x400.jpg';
import ChangingTheFaceofScience_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/changing-the-face-of-science-menu-thumbnails-400x400.jpg';
import FindingMyLeadershipVoice_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/finding-my-leadership-voice-menu-thumbnails-400x400.jpg';
import PresidentsMessage_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/presidents-message-menu-thumbnails-400x400.jpg';
import ATitanRaisestheBar_Thumbnail from '../../images/spring-summer-2024/menu-thumbnails/a-titan-raises-the-bar-menu-thumbnails-400x400.jpg';

import AFamilyofTitanScholars_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/a-family-of-titan-scholars-legacy-small-thumbnails-100x100.jpg';
import AHomeBehindtheLens_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/a-home-behind-the-lens-small-thumbnails-100x100.jpg';
import ChangingTheFaceofScience_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/changing-the-face-of-science-small-thumbnails-100x100.jpg';
import FindingMyLeadershipVoice_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/finding-my-leadership-voice-small-thumbnails-100x100.jpg';
import PresidentsMessage_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/presidents-message-small-thumbnails-100x100.jpg';
import ATitanRaisestheBar_SmallThumbnail from '../../images/spring-summer-2024/small-thumbnails/a-titan-raises-the-bar-small-thumbnails-100x100.jpg';

import AFamilyofTitanScholars_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/a-family-of-titan-scholars-index-thumbnails-1920x1080.jpg';
import AHomeBehindtheLens_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/a-home-behind-the-lens-index-thumbnails-1920x1080.jpg';
import ChangingTheFaceofScience_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/changing-the-face-of-science-index-thumbnails-1920x1080.jpg';
import FindingMyLeadershipVoice_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/finding-my-leadership-voice-index-thumbnails-1920x1080.jpg';
import PresidentsMessage_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/presidents-message-index-thumbnails-1920x1080.jpg';
import ATitanRaisestheBar_IndexThumbnail from '../../images/spring-summer-2024/index-thumbnails/a-titan-raises-the-bar-index-thumbnails-1920x1080.jpg';

import AFamilyofTitanScholars_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/a-family-of-titan-scholars-legacy-small-thumbnails-rect-250x125.jpg';
import AHomeBehindtheLens_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/a-home-behind-the-lens-small-thumbnails-rect-250x125.jpg';
import ChangingTheFaceofScience_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/changing-the-face-of-science-small-thumbnails-rect-250x125.jpg';
import FindingMyLeadershipVoice_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/finding-my-leadership-voice-small-thumbnails-rect-250x125.jpg';
import PresidentsMessage_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/presidents-message-small-thumbnails-rect-250x125.jpg';
import ATitanRaisestheBar_SmallRectThumbnail from '../../images/spring-summer-2024/small-thumbnails-rect/a-titan-raises-the-bar-small-thumbnails-rect-250x125.jpg';

// import ClassNotes_Thumbnail from '../../images/fall-winter-2023/class-notes.jpg';
// import ClassNotes_IndexThumbnail from '../../images/fall-winter-2023/class-notes-banner.jpg';

// export const fallSummer2024Stories = [
//   {
//     slug: 'example-story',
//     title: 'Cillum occaecat nisi elit ut.',
//     subtitle: 'Ullamco ipsum sunt consectetur elit eu non amet dolor reprehenderit incididunt. Nostrud elit incididunt ad enim. Duis labore cillum laborum ullamco.',
//     storyType: 'feature',
//     thumbnail: exampleStory_Thumbnail,
//     thumbnailSmall: exampleStory_Thumbnail,
//     thumbnailSmallRect: exampleStory_Thumbnail,
//     thumbnailIndex: exampleStory_IndexThumbnail,
//     homepageTextPosition: 'left'
//   },

// ];

export const fallSummer2024Stories = [
  {
    slug: 'a-home-behind-the-lens',
    title: 'A Home Behind the Lens',
    subtitle: 'From the Daily Titan to the Los Angeles Times, CSUF alumna Christina House wins a coveted Pulitzer Prize for feature photography.',
    storyType: 'feature',
    thumbnail: AHomeBehindtheLens_Thumbnail,
    thumbnailSmall: AHomeBehindtheLens_SmallThumbnail,
    thumbnailSmallRect: AHomeBehindtheLens_SmallRectThumbnail,
    thumbnailIndex: AHomeBehindtheLens_IndexThumbnail,
    homepageTextPosition: 'left'
  },


  {
    slug: 'a-family-of-titan-scholars',
    title: 'A Family of Titan Scholars',
    subtitle: 'The De La Cruzes are keeping scholarships in the family, with four siblings in the President’s Scholars Program and one in the Abrego Future Scholars Program.',
    storyType: 'feature',
    thumbnail: AFamilyofTitanScholars_Thumbnail,
    thumbnailSmall: AFamilyofTitanScholars_SmallThumbnail,
    thumbnailSmallRect: AFamilyofTitanScholars_SmallRectThumbnail,
    thumbnailIndex: AFamilyofTitanScholars_IndexThumbnail,
    homepageTextPosition: 'left'
  },


  {
    slug: 'changing-the-face-of-science',
    title: 'Changing the Face of Science',
    subtitle: 'Geology alumni with diverse identities co-host the PBS show ‘Eons’ and take viewers on a journey through the prehistoric past.',
    storyType: 'feature',
    thumbnail: ChangingTheFaceofScience_Thumbnail,
    thumbnailSmall: ChangingTheFaceofScience_SmallThumbnail,
    thumbnailSmallRect: ChangingTheFaceofScience_SmallRectThumbnail,
    thumbnailIndex: ChangingTheFaceofScience_IndexThumbnail,
    homepageTextPosition: 'left'
  },

  {
    slug: 'finding-my-leadership-voice',
    title: 'Finding My Leadership Voice',
    subtitle: 'With support from Cal State Fullerton’s Center for Leadership, accounting alumna Selena-Andria Liu lands a dream job at Big Four accounting firm Ernst & Young.',
    storyType: 'feature',
    thumbnail: FindingMyLeadershipVoice_Thumbnail,
    thumbnailSmall: FindingMyLeadershipVoice_SmallThumbnail,
    thumbnailSmallRect: FindingMyLeadershipVoice_SmallRectThumbnail,
    thumbnailIndex: FindingMyLeadershipVoice_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'a-titan-raises-the-bar',
    title: 'A Titan Raises the Bar',
    subtitle: 'Alumnus Gregory Washington competed on Cal State Fullerton’s moot court team. Now he’s an attorney who has represented clients like Google, PG&E, Uber and Apple Inc.',
    storyType: 'feature',
    thumbnail: ATitanRaisestheBar_Thumbnail,
    thumbnailSmall: ATitanRaisestheBar_SmallThumbnail,
    thumbnailSmallRect: ATitanRaisestheBar_SmallRectThumbnail,
    thumbnailIndex: ATitanRaisestheBar_IndexThumbnail,
    homepageTextPosition: 'left'
  },

  {
    slug: 'presidents-message',
    title: "President's Message",
    subtitle: 'Cal State Fullerton President Sylvia Alva introduces the spring/summer 2024 issues of Titan magazine and the university’s Fullerton Forward 2024-29 strategic plan.',
    storyType: 'feature',
    thumbnail: PresidentsMessage_Thumbnail,
    thumbnailSmall: PresidentsMessage_SmallThumbnail,
    thumbnailSmallRect: PresidentsMessage_SmallRectThumbnail,
    thumbnailIndex: PresidentsMessage_IndexThumbnail,
    homepageTextPosition: 'left'
  },

];