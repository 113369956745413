import IAmAndAlwaysWillBeATitan_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/i-am-and-always-will-be-a-titan.jpg';
import TitanInTheOutfield_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/titan-in-the-outfield.jpg';
import TheSecretsInTheHotSauce_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/the-secrets-in-the-hot-sauce.jpg';
import ChloeTheEngineer_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/chloe-the-engineer.jpg';
import presidentsMessage_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/presidents-message.jpg';
import campaign_Thumbnail from '../../images/spring-summer-2023/menu-thumbnails/campaign.jpg';

import IAmAndAlwaysWillBeATitan_SmallThumbnail from '../../images/spring-summer-2023/small-thumbnails/campaign.jpg';
import TitanInTheOutfield_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/under-the-big-top.jpg';
import TheSecretsInTheHotSauce_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/california-condors.jpg';
import ChloeTheEngineer_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/unbreakable-zack-collie.jpg';
import presidentsMessage_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/presidents-message.jpg';
import campaign_SmallThumbnail from '../../images/spring-summer-2023/small-thumbnails/campaign.jpg';

import IAmAndAlwaysWillBeATitan_IndexThumbnail from '../../images/spring-summer-2023/index-thumbnails/i-am-and-always-will-be-a-titan.jpg';
import TitanInTheOutfield_IndexThumbnail from '../../images/spring-summer-2023/index-thumbnails/titan-in-the-outfield.jpg';
import TheSecretsInTheHotSauce_IndexThumbnail from '../../images/spring-summer-2023/index-thumbnails/the-secrets-in-the-hot-sauce.jpg';
import ChloeTheEngineer_IndexThumbnail from '../../images/spring-summer-2023/index-thumbnails/chloe-the-engineer.jpg';
import presidentsMessage_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/presidents-message.jpg';
import campaign_IndexThumbnail from '../../images/spring-summer-2023/index-thumbnails/campaign.jpg';

import IAmAndAlwaysWillBeATitan_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/presidents-message.jpg';
import TitanInTheOutfield_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/under-the-big-top.jpg';
import TheSecretsInTheHotSauce_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/california-condors.jpg';
import ChloeTheEngineer_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/unbreakable-zack-collie.jpg';
import presidentsMessage_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/presidents-message.jpg';
import campaign_SmallRectThumbnail from '../../images/spring-summer-2023/small-thumbnails-rect/campaign.jpg';





export const springSummer2023Stories = [

  {
    slug: 'titan-in-the-outfield',
    title: 'A Titan Takes the Field',
    subtitle: 'Alumna Kelsie Whitmore knew there would be a first woman to play Major League Baseball. She’s on the road to achieving that milestone. ',
    storyType: 'feature',
    thumbnail: TitanInTheOutfield_Thumbnail,
    thumbnailSmall: TitanInTheOutfield_SmallThumbnail,
    thumbnailSmallRect: TitanInTheOutfield_SmallRectThumbnail,
    thumbnailIndex: TitanInTheOutfield_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'the-secrets-in-the-hot-sauce',
    title: 'The Secret’s in the Hot Sauce',
    subtitle: 'First came the Instagram handle, then came the hot sauce. Cal State Fullerton alumni share their journey to creating the Truff luxury condiment brand.',
    storyType: 'feature',
    thumbnail: TheSecretsInTheHotSauce_Thumbnail,
    thumbnailSmall: TheSecretsInTheHotSauce_SmallThumbnail,
    thumbnailSmallRect: TheSecretsInTheHotSauce_SmallRectThumbnail,
    thumbnailIndex: TheSecretsInTheHotSauce_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'chloe-the-engineer',
    title: 'Chloe the Engineer Stomps Out Stereotypes',
    subtitle: 'Alumna breaks barriers in STEM: ‘You can succeed in engineering and be a girl in pink heels.’',
    storyType: 'feature',
    thumbnail: ChloeTheEngineer_Thumbnail,
    thumbnailSmall: ChloeTheEngineer_SmallThumbnail,
    thumbnailSmallRect: ChloeTheEngineer_SmallRectThumbnail,
    thumbnailIndex: ChloeTheEngineer_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'raising-270-million',
    title: 'Raising $270 Million: A Titan Success Story',
    subtitle: 'Cal State Fullerton celebrates achievements from its first-ever comprehensive philanthropic campaign.',
    storyType: 'feature',
    thumbnail: campaign_Thumbnail,
    thumbnailSmall: campaign_SmallThumbnail,
    thumbnailSmallRect: campaign_SmallRectThumbnail,
    thumbnailIndex: campaign_IndexThumbnail,
    homepageTextPosition: 'center',
    external: 'TRUE'
  },
  {
    slug: 'i-am-and-always-will-be-a-titan',
    title: 'Once a Titan, Always a Titan',
    subtitle: 'Fram and Julie Virjee Bid Farewell',
    storyType: 'feature',
    thumbnail: IAmAndAlwaysWillBeATitan_Thumbnail,
    thumbnailSmall: IAmAndAlwaysWillBeATitan_SmallThumbnail,
    thumbnailSmallRect: IAmAndAlwaysWillBeATitan_SmallRectThumbnail,
    thumbnailIndex: IAmAndAlwaysWillBeATitan_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: presidentsMessage_Thumbnail,
    thumbnailSmall: presidentsMessage_SmallThumbnail,
    thumbnailSmallRect: presidentsMessage_SmallRectThumbnail,
    thumbnailIndex: presidentsMessage_IndexThumbnail,
    homepageTextPosition: 'left'
  }
];