import React from "react"
import { CSSTransition } from 'react-transition-group';
// import { Link } from "gatsby"
// import Link from 'gatsby-plugin-transition-link'
import Link from "gatsby-plugin-transition-link/AniLink";
import { Scrollbars } from 'react-custom-scrollbars';
import { TransitionPortal } from "gatsby-plugin-transition-link";
import ScrollProgressRead from 'react-scroll-progress-read';
import Headroom from 'react-headroom';
import Icon from './icons'

import {spring2020stories} from './spring-2020-stories/stories';
import {fall2020stories} from './fall-winter-2020-stories/stories';
import {spring2021stories} from './spring-summer-2021-stories/stories';
import {fallWinter2021stories} from './fall-winter-2021-stories/stories';
import {springSummer2022Stories} from './spring-summer-2022-stories/stories';
import {fallWinter2022Stories} from './fall-winter-2022-stories/stories';
import {springSummer2023Stories} from './spring-summer-2023-stories/stories';
import {fallWinter2023Stories} from './fall-winter-2023-stories/stories';
import {fallSummer2024Stories} from './spring-summer-2024-stories/stories';
import {fallWinter2024Stories} from './fall-winter-2024-stories/stories';


import ClassNotesThumb from '../images/fall-winter-2022/class-notes-thumb.jpg';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      Issueactive: false
    };
    this.mainNav = React.createRef();
    this.mainNavButton = React.createRef();
    this.issueList = React.createRef();
    this.issueListButton = React.createRef();
  }

  toggleMenu = () => {
    this.setState({ Issueactive: false });

    if (this.state.active) {
      this.setState({active: false});
      this.mainNavButton.current.focus();
    } else {
      this.setState({active: true});
      this.mainNav.current.focus();
    }
  }

  toggleIssue = () => {
    this.setState({active: false});

    if (this.state.Issueactive) {
      this.setState({Issueactive: false});
      this.issueListButton.current.focus();
    } else {
      this.setState({Issueactive: true});
      this.issueList.current.focus();
    }
  }

  render() {

    var issue = this.props.issue;

    
    if (issue == 'fall-winter-2024') {
      var issueDisplay = 'Fall/Winter 2024';
    } else if (issue == 'spring-summer-2024') {
      var issueDisplay = 'Spring/Summer 2024';
    } else if (issue == 'fall-winter-2023') {
      var issueDisplay = 'Fall/Winter 2023';
    } else if (issue == 'spring-summer-2023') {
      var issueDisplay = 'Spring/Summer 2023';
    } else if (issue == 'fall-winter-2022') {
      var issueDisplay = 'Fall/Winter 2022';
    } else if (issue == 'spring-summer-2022') {
      var issueDisplay = 'Spring/Summer 2022';
    } else if (issue == 'fall-winter-2021') {
      var issueDisplay = 'Fall/Winter 2021';
    } else if (issue == 'spring-summer-2021') {
      var issueDisplay = 'Spring/Summer 2021';
    } else if (issue == 'spring-2020') {
      var issueDisplay = 'Spring 2020';
    } else if (issue == 'fall-winter-2020') {
      var issueDisplay = 'Fall/Winter 2020';
    }

    if (issue == 'fall-winter-2024') {
      var stories = fallWinter2024Stories;
    } else if (issue == 'spring-summer-2024') {
      var stories = fallSummer2024Stories;
    } else if (issue == 'fall-winter-2023') {
      var stories = fallWinter2023Stories;
    } else if (issue == 'spring-summer-2023') {
      var stories = springSummer2023Stories;
    } else if (issue == 'fall-winter-2022') {
      var stories = fallWinter2022Stories;
    } else if (issue == 'spring-summer-2022') {
      var stories = springSummer2022Stories;
    } else if (issue == 'fall-winter-2021') {
      var stories = fallWinter2021stories;
    } else if (issue == 'spring-summer-2021') {
      var stories = spring2021stories;
    } else if (issue == 'spring-2020') {
      var stories = spring2020stories;
    } else if (issue == 'fall-winter-2020') {
      var stories = fall2020stories;
    }

    const list = stories.map(function(story){
      var slug = "/" + issue + "/" + story.slug + "/";

      if (story.external === 'TRUE') {

        if (story.url) {
          var link = story.url;
        } else {
          var link = process.env.SITE_URL + slug;
        }

        return (
          <li className={story.storyType} key={story.slug}>
            <a
            className="link"
            href={link}
            target="_blank">
              <img src={story.thumbnail} alt="" className="link-image" />
              <span className="link-text">{story.title}</span>
            </a>
          </li>
        )
      } else {
        return (
          <li className={story.storyType} key={story.slug}>
            <Link
            className="link"
            paintDrip
            hex="#FF7900"
            duration={.75}
            to={slug}>
              <img src={story.thumbnail} alt="" className="link-image" />
              <span className="link-text">{story.title}</span>
            </Link>
          </li>
        )
      }

    })

    var headerClasses = this.props.headerStyle;

    //header hide
    if (this.props.headerHide) {
      headerClasses = this.props.headerStyle + ' hidden';
    }

    return (
      <React.Fragment>
        <TransitionPortal level="top">
          <ScrollProgressRead
          className="progress-bar"
          backgroundColor="rgba(0,0,0,.65)"
          barColor="#FF7900"
          height="3px" />
          <Headroom>
          <header className={headerClasses}>
            {/* <img src={Titan} alt="Titan" id="wordmark" /> */}
            <Link paintDrip hex="#FF7900" duration={.75} to="/" className="branding-home-link" tabIndex="2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.29 111.13" id="wordmark"><g data-name="Layer 2"><title>Titan</title><path d="M0 0v25.9h28.35v85.23h33.43V25.9h28.35V0zm98.05 111.13h33.43V0H98.05zM139.21 0v25.9h28.35v85.23H201V25.9h28.35V0zM272.3 31.5l10.15 36.22h-20.3zM253.05 0l-35.88 111.13h32.73l4.9-17.5h35l4.9 17.5h35.35L294.17 0zm151.56 0v59.32L371.19 0h-34.3v111.13h31.68v-63l35.17 63h32.55V0z" data-name="Layer 1"/></g></svg>
              <span className="tagline"><span className="tagline-fix">The Digital Magazine of<br />Cal State Fullerton</span></span>
            </Link>
            <div className="nav-right">
              <button tabIndex="4" onClick={this.toggleMenu} className={this.state.active ? 'hamburger hamburger--spin is-active': 'hamburger hamburger--spin'}  type="button" id="nav-toggle" aria-labelledby="nav-toggle-label" ref={this.mainNavButton}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
                <span className="nav-toggle-text" id="nav-toggle-label">Menu</span>
              </button>

              <button tabIndex="3" onClick={this.toggleIssue} className={this.state.Issueactive ? 'issue is-active': 'issue'}  type="button" id="issue-toggle" title="Select Issue" ref={this.issueListButton}>{issueDisplay} <Icon name="arrow-down" alt="Down Arrow" /></button>
              
            </div>
            <CSSTransition
            timeout={200}
            in={this.state.active}
            classNames={{
              enter: "bounceInDown open",
              enterActive: "bounceInDown open",
              enterDone: 'bounceInDown open',
              exit: 'bounceOutUp open',
              exitActive: 'bounceOutUp open',
              exitDone: 'bounceOutUp open'
            }}
            >
            <nav className="animated" id="mainNav" tabIndex="-1" ref={this.mainNav}>
              <Scrollbars
              renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
              style={{ width: 500, maxWidth: '100%', height: '80vh' }}>
                <h2>In This Issue:</h2>
                <ul>
                  {list}
                  {issue != 'fall-winter-2024' &&
                  <li  key="class-notes">
                    <a href="https://alumni.fullerton.edu/class-notes/" target="_blank" className="link" >
                      <img src={ClassNotesThumb} alt="" className="link-image" />
                      <span className="link-text">Alumni Class Notes</span>
                    </a>
                  </li>
                  }
                </ul>
              </Scrollbars>
            </nav>
            </CSSTransition>
            <CSSTransition
              timeout={200}
              in={this.state.Issueactive}
              classNames={{
                enter: "animated",
                enterActive: "animated bounceInDown open",
                enterDone: 'animated bounceInDown open',
                exit: 'animated bounceOutUp open',
                exitActive: 'animated bounceOutUp open',
                exitDone: 'animated bounceOutUp open'
              }}
              >
              <ul id="issueList" tabIndex="-1" aria-label="Issue List" ref={this.issueList}>
              <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/'>
                  Fall/Winter 2024
                </Link>
                </li>
               <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/spring-summer-2024'>
                  Spring/Summer 2024
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/fall-winter-2023'>
                  Fall/Winter 2023
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/spring-summer-2023'>
                  Spring/Summer 2023
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/fall-winter-2022'>
                  Fall/Winter 2022
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/spring-summer-2022'>
                  Spring/Summer 2022
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/fall-winter-2021'>
                  Fall/Winter 2021
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/spring-summer-2021'>
                  Spring/Summer 2021
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/fall-winter-2020'>
                  Fall/Winter 2020
                </Link>
                </li>
                <li>
                <Link
                className="link"
                paintDrip
                hex="#FF7900"
                duration={.75}
                to='/spring-2020'>
                  Spring 2020
                </Link>
                </li>
              </ul>
              </CSSTransition>
          </header>
          </Headroom>
          <CSSTransition
            timeout={200}
            in={this.state.active}
            classNames={{
              enter: "fadeIn open",
              enterActive: "fadeIn open",
              enterDone: 'fadeIn open',
              exit: 'fadeOut open',
              exitActive: 'fadeOut open',
              exitDone: ''
            }}
            >
            <div onClick={this.toggleMenu} className="animated nav-tint" />
          </CSSTransition>
        </TransitionPortal>
      </React.Fragment>
    );
  }
}