import '../styles/base.scss';
import React from "react"
import Header from "./header"
import Footer from "./footer"
import Metadata from './sitemetadata';
// import { TransitionPortal } from "gatsby-plugin-transition-link";

export default (props) => {

  var headerStyle = '';
  if (props.headerStyle) {
    headerStyle = props.headerStyle;
  } else {
    headerStyle = null;
  }

  var headerHide = '';
  if (props.headerHide) {
    headerHide = props.headerHide;
  } else {
    headerHide = null;
  }

  var LocationInfo = '';
  if (props.locationInfo) {
    LocationInfo = props.locationInfo.pathname;
  } else {
    LocationInfo = null;
  }

  return (
    <React.Fragment>
      <Metadata locationInfo={LocationInfo} />
      <noscript>Some page features may require javascript to run.</noscript>
      <div id="skipnav">
        <a href="#MainContent" tabIndex="1">Skip to Content (Press Enter)</a>
      </div>
      {/* <TransitionPortal> */}
        <Header headerStyle={headerStyle} headerHide={headerHide} issue={props.issue} />
      {/* </TransitionPortal> */}
      <main id="MainContent">
        {props.children}
      </main>
      <Footer />
    </React.Fragment>
  )
}