import React from "react"
import { Helmet } from "react-helmet"
import { graphql, StaticQuery } from "gatsby"
import socialBanner from "../images/fall-winter-2020/social-banners/main.jpg";
import { withPrefix } from 'gatsby';

// import FlyingFocus from "../js/flying-focus";

const SiteMetadata = (props) => (
  <StaticQuery
    query={graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { siteUrl, title, description },
      },
    }) => (
      <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
        <html lang="en" />
        <link rel="canonical" href={`${siteUrl}${props.locationInfo || "/"}`} />
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
        <meta property="og:url" content={`${siteUrl}${props.locationInfo || "/"}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content={title} />
        <meta name="description" content={description} />

        {/* Schema.org markup for Google+ */}
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content={description} />
        <meta itemprop="image" content={socialBanner} />

        {/* Twitter Card data */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@csuf" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* <meta name="twitter:creator" content="@author_handle"> */}
        {/* Twitter summary card with large image must be at least 280x150px */}
        <meta name="twitter:image:src" content={socialBanner} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Titan - The Magazine of Cal State Fullerton" />

        {/* Open Graph data */}
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${siteUrl}${props.locationInfo || "/"}`} />
        <meta property="og:image" content={socialBanner} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Titan" />

        <link rel="apple-touch-icon" sizes="180x180" href={ withPrefix('/apple-touch-icon.png') } />
        <link rel="icon" type="image/png" sizes="32x32" href={ withPrefix('/favicon-32x32.png') } />
        <link rel="icon" type="image/png" sizes="16x16" href={ withPrefix('/favicon-16x16.png') } />
        <link rel="manifest" href={ withPrefix('/site.webmanifest') } />
        <link rel="mask-icon" href={ withPrefix('/safari-pinned-tab.svg') } color="#00274c" />
        <meta name="msapplication-TileColor" content="#00274c" />
        <meta name="theme-color" content="#00274c" />

        {/* <script src={withPrefix('/js/flying-focus.js')} type="text/javascript" /> */}

        <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0047/9029.js" async="async"></script>
      </Helmet>
    )}
  />
)

export default SiteMetadata