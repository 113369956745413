import AnEducatorFirst_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/an-educator-first.jpg';
import AnEducatorFirst_IndexThumbnail from '../../images/fall-winter-2023/index-thumbnails/an-educator-first.jpg';

import Mandalorian_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/a-titan-steps-into-a-galaxy-far-far-away.jpg';
import Mandalorian_IndexThumbnail from '../../images/fall-winter-2023/index-thumbnails/a-titan-steps-into-a-galaxy-far-far-away.jpg';

import TSN_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/sports-broadcast-students-prepare-for-prime-time.jpg';
import TSN_IndexThumbnail from '../../images/fall-winter-2023/index-thumbnails/sports-broadcast-students-prepare-for-prime-time.jpg';

import Arboretum_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/farm-to-pantry.jpg';
import Arboretum_IndexThumbnail from '../../images/fall-winter-2023/index-thumbnails/farm-to-pantry.jpg';

import TCM_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/finance-students-learn-secrets-of-the-trade.jpg';
import TCM_IndexThumbnail from '../../images/fall-winter-2023/index-thumbnails/finance-students-learn-secrets-of-the-trade.jpg';

import PresMSG_Thumbnail from '../../images/fall-winter-2023/menu-thumbnails/presidents-message.jpg';

import ClassNotes_Thumbnail from '../../images/fall-winter-2023/class-notes.jpg';
import ClassNotes_IndexThumbnail from '../../images/fall-winter-2023/class-notes-banner.jpg';

export const fallWinter2023Stories = [
  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: PresMSG_Thumbnail,
    thumbnailSmall: PresMSG_Thumbnail,
    thumbnailSmallRect: PresMSG_Thumbnail,
    thumbnailIndex: PresMSG_Thumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'a-titan-steps-into-a-galaxy-far-far-away',
    title: 'A Titan Steps Into a Galaxy Far, Far Away',
    subtitle: 'Cal State Fullerton alumnus Omid Abtahi plays Dr. Penn Pershing in Disney+’s ‘The Mandalorian.’',
    storyType: 'feature',
    thumbnail: Mandalorian_Thumbnail,
    thumbnailSmall: Mandalorian_Thumbnail,
    thumbnailSmallRect: Mandalorian_IndexThumbnail,
    thumbnailIndex: Mandalorian_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'sports-broadcast-students-prepare-for-prime-time',
    title: 'Sports Broadcast Students Prepare for Prime Time',
    subtitle: 'Titans Sports Network gives communications students hands-on experience in producing more than 120 live events for ESPN+ each year.',
    storyType: 'feature',
    thumbnail: TSN_Thumbnail,
    thumbnailSmall: TSN_Thumbnail,
    thumbnailSmallRect: TSN_IndexThumbnail,
    thumbnailIndex: TSN_IndexThumbnail,
    homepageTextPosition: 'left'
  },

  {
    slug: 'finance-students-learn-secrets-of-the-trade',
    title: 'Finance Students Learn Secrets of the Trade',
    subtitle: 'CSUF’s globally recognized Titan Capital Management teaches students the art of investing and gives them a competitive edge in the financial industry.',
    storyType: 'feature',
    thumbnail: TCM_Thumbnail,
    thumbnailSmall: TCM_Thumbnail,
    thumbnailSmallRect: TCM_IndexThumbnail,
    thumbnailIndex: TCM_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'farm-to-pantry',
    title: 'From Farm to Food Pantry',
    subtitle: 'The Fullerton Arboretum and CSUF Campus Garden keep the ASI Food Pantry stocked with fresh fruits and vegetables.',
    storyType: 'feature',
    thumbnail: Arboretum_Thumbnail,
    thumbnailSmall: Arboretum_Thumbnail,
    thumbnailSmallRect: Arboretum_IndexThumbnail,
    thumbnailIndex: Arboretum_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  {
    slug: 'class-notes',
    title: 'Alumni Class Notes',
    subtitle: 'Class Notes is the place to catch up. Let fellow alumni know about career moves, awards and honors, publications and performances, births and marriages, and other life events.',
    storyType: 'feature',
    thumbnail: ClassNotes_Thumbnail,
    thumbnailSmall: ClassNotes_Thumbnail,
    thumbnailSmallRect: ClassNotes_IndexThumbnail,
    thumbnailIndex: ClassNotes_IndexThumbnail,
    homepageTextPosition: 'left',
    external: 'TRUE',
    url: 'https://alumni.fullerton.edu/class-notes/',
    buttonText: 'Visit Class Notes',
  },
  {
    slug: 'an-educator-first',
    title: 'An Educator First',
    subtitle: 'Professor and higher education administrator Sylvia Alva steps into role as president of Cal State Fullerton.',
    storyType: 'feature',
    thumbnail: AnEducatorFirst_Thumbnail,
    thumbnailSmall: AnEducatorFirst_Thumbnail,
    thumbnailSmallRect: AnEducatorFirst_IndexThumbnail,
    thumbnailIndex: AnEducatorFirst_IndexThumbnail,
    homepageTextPosition: 'left'
  },
];