import React from 'react'
import icons from '../images/icons/icons-nocolor.svg'

const Icon = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={`icon ${props.class} icon-${props.name}`}
			role="img"
		>
      <title>{props.alt}</title>
			<use xlinkHref={`${icons}#${props.name}`} />
		</svg>
	)
}
export default Icon