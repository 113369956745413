import boundlessOpportunity from '../../images/spring-2020/menu-thumbnails/boundless-opportunity.jpg';
import callingInCounseling from '../../images/spring-2020/menu-thumbnails/calling-in-counseling.jpg';
import lessonsSweetPotato from '../../images/spring-2020/menu-thumbnails/lessons-sweet-potato.jpg';
import mentoringProjectReboundStudents from '../../images/spring-2020/menu-thumbnails/mentoring-project-rebound-students.jpg';
import onePitchAtATime from '../../images/spring-2020/menu-thumbnails/one-pitch-at-a-time.jpg';
import digitalTitanMag from '../../images/spring-2020/menu-thumbnails/digital-titan-mag.jpg';
import softballClubhouseMakeover from '../../images/spring-2020/menu-thumbnails/softball-clubhouse-makeover.jpg';
import traditionsTechnologyBoostBeeHealth from '../../images/spring-2020/menu-thumbnails/traditions-technology-boost-bee-health.jpg';

import boundlessOpportunitySmall from '../../images/spring-2020/small-thumbnails/boundless-opportunity.jpg';
import callingInCounselingSmall from '../../images/spring-2020/small-thumbnails/calling-in-counseling.jpg';
import lessonsSweetPotatoSmall from '../../images/spring-2020/small-thumbnails/lessons-sweet-potato.jpg';
import mentoringProjectReboundStudentsSmall from '../../images/spring-2020/small-thumbnails/mentoring-project-rebound-students.jpg';
import onePitchAtATimeSmall from '../../images/spring-2020/small-thumbnails/one-pitch-at-a-time.jpg';
import digitalTitanMagSmall from '../../images/spring-2020/small-thumbnails/digital-titan-mag.jpg';
import softballClubhouseMakeoverSmall from '../../images/spring-2020/small-thumbnails/softball-clubhouse-makeover.jpg';
import traditionsTechnologyBoostBeeHealthSmall from '../../images/spring-2020/small-thumbnails/traditions-technology-boost-bee-health.jpg';

import boundlessOpportunityIndex from '../../images/spring-2020/index-thumbnails/boundless-opportunity.jpg';
import callingInCounselingIndex from '../../images/spring-2020/index-thumbnails/calling-in-counseling.jpg';
import lessonsSweetPotatoIndex from '../../images/spring-2020/index-thumbnails/lessons-sweet-potato.jpg';
import mentoringProjectReboundStudentsIndex from '../../images/spring-2020/index-thumbnails/mentoring-project-rebound-students.jpg';
import onePitchAtATimeIndex from '../../images/spring-2020/index-thumbnails/one-pitch-at-a-time.jpg';
import digitalTitanMagIndex from '../../images/spring-2020/index-thumbnails/digital-titan-mag.jpg';
import softballClubhouseMakeoverIndex from '../../images/spring-2020/index-thumbnails/softball-clubhouse-makeover.jpg';
import traditionsTechnologyBoostBeeHealthIndex from '../../images/spring-2020/index-thumbnails/traditions-technology-boost-bee-health.jpg';

import boundlessOpportunitySmallRect from '../../images/spring-2020/small-thumbnails-rect/boundless-opportunity.jpg';
import callingInCounselingSmallRect from '../../images/spring-2020/small-thumbnails-rect/calling-in-counseling.jpg';
import lessonsSweetPotatoSmallRect from '../../images/spring-2020/small-thumbnails-rect/lessons-sweet-potato.jpg';
import mentoringProjectReboundStudentsSmallRect from '../../images/spring-2020/small-thumbnails-rect/mentoring-project-rebound-students.jpg';
import onePitchAtATimeSmallRect from '../../images/spring-2020/small-thumbnails-rect/one-pitch-at-a-time.jpg';
import digitalTitanMagSmallRect from '../../images/spring-2020/small-thumbnails-rect/digital-titan-mag.jpg';
import softballClubhouseMakeoverSmallRect from '../../images/spring-2020/small-thumbnails-rect/softball-clubhouse-makeover.jpg';
import traditionsTechnologyBoostBeeHealthSmallRect from '../../images/spring-2020/small-thumbnails-rect/traditions-technology-boost-bee-health.jpg';


export const spring2020stories = [
  {
    slug: 'one-pitch-at-a-time',
    title: 'One Pitch at a Time',
    subtitle: 'From Goodwin Field to the Big Leagues, Titan Baseball Hits It Out of the Park',
    storyType: 'feature',
    thumbnail: onePitchAtATime,
    thumbnailSmall: onePitchAtATimeSmall,
    thumbnailSmallRect: onePitchAtATimeSmallRect,
    thumbnailIndex: onePitchAtATimeIndex,
    category: 'baseball'
  },
  {
    slug: 'lessons-sweet-potato',
    title: 'Lessons From the Lowly Sweet Potato',
    subtitle: 'U-ACRE Researchers Unearth Knowledge and Cultivate Sustainability',
    storyType: 'feature',
    thumbnail: lessonsSweetPotato,
    thumbnailSmall: lessonsSweetPotatoSmall,
    thumbnailSmallRect: lessonsSweetPotatoSmallRect,
    thumbnailIndex: lessonsSweetPotatoIndex,
    category: 'uacre'
  },
  {
    slug: 'boundless-opportunity',
    title: 'Boundless Opportunity',
    subtitle: 'Project Rebound Opens Doors for the Formerly Incarcerated',
    storyType: 'feature',
    thumbnail: boundlessOpportunity,
    thumbnailSmall: boundlessOpportunitySmall,
    thumbnailSmallRect: boundlessOpportunitySmallRect,
    thumbnailIndex: boundlessOpportunityIndex,
    category: 'project rebound'
  },
  {
    slug: 'softball-clubhouse-makeover',
    title: 'Softball On Deck for a Game-Changing Clubhouse',
    subtitle: '',
    storyType: 'regular',
    thumbnail: softballClubhouseMakeover,
    thumbnailSmall: softballClubhouseMakeoverSmall,
    thumbnailSmallRect: softballClubhouseMakeoverSmallRect,
    thumbnailIndex: softballClubhouseMakeoverIndex,
    category: 'baseball'
  },
  {
    slug: 'arboretum-beehive-research',
    title: 'Arboretum Buzzes With Beehive Research',
    subtitle: '',
    storyType: 'regular',
    thumbnail: traditionsTechnologyBoostBeeHealth,
    thumbnailSmall: traditionsTechnologyBoostBeeHealthSmall,
    thumbnailSmallRect: traditionsTechnologyBoostBeeHealthSmallRect,
    thumbnailIndex: traditionsTechnologyBoostBeeHealthIndex,
    category: 'uacre'
  },
  {
    slug: 'calling-in-counseling',
    title: 'Project Rebound Alumnus Finds Calling in Counseling',
    subtitle: '',
    storyType: 'regular',
    thumbnail: callingInCounseling,
    thumbnailSmall: callingInCounselingSmall,
    thumbnailSmallRect: callingInCounselingSmallRect,
    thumbnailIndex: callingInCounselingIndex,
    category: 'project rebound'
  },
  {
    slug: 'mentoring-project-rebound-students',
    title: 'Retired Industry Leaders Mentor Project Rebound Students',
    subtitle: '',
    storyType: 'regular',
    thumbnail: mentoringProjectReboundStudents,
    thumbnailSmall: mentoringProjectReboundStudentsSmall,
    thumbnailSmallRect: mentoringProjectReboundStudentsSmallRect,
    thumbnailIndex: mentoringProjectReboundStudentsIndex,
    category: 'project rebound'
  },
  {
    slug: 'digital-titan-mag',
    title: 'Introducing the New Digital TITAN Magazine',
    subtitle: '',
    desc: 'TITAN, the signature magazine of Cal State Fullerton, is proud to premiere its first fully digital issue. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.',
    storyType: 'regular',
    thumbnail: digitalTitanMag,
    thumbnailSmall: digitalTitanMagSmall,
    thumbnailSmallRect: digitalTitanMagSmallRect,
    thumbnailIndex: digitalTitanMagIndex
  }
];