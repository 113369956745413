import blindSopranoShootsForTheMoonThumbnail from '../../images/spring-summer-2021/menu-thumbnails/blind-soprano-shoots-for-the-moon.jpg';
import buildingABrotherhoodThumbnail from '../../images/spring-summer-2021/menu-thumbnails/building-a-brotherhood.jpg';
import campaignProgressThumbnail from '../../images/spring-summer-2021/menu-thumbnails/campaign-progress.jpg';
import presidentsMessageThumbnail from '../../images/spring-summer-2021/menu-thumbnails/presidents-message.jpg';
import tasteOfBobaTeaSuccessThumbnail from '../../images/spring-summer-2021/menu-thumbnails/taste-of-boba-tea-success.jpg';
import touchdownOnTheRedPlanetThumbnail from '../../images/spring-summer-2021/menu-thumbnails/touchdown-on-the-red-planet.jpg';
import vanishingWordsThumbnail from '../../images/spring-summer-2021/menu-thumbnails/vanishing-words.jpg';
import workplaceLessonsFromThePandemicThumbnail from '../../images/spring-summer-2021/menu-thumbnails/workplace-lessons-from-the-pandemic.jpg';

import blindSopranoShootsForTheMoonThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/blind-soprano-shoots-for-the-moon.jpg';
import buildingABrotherhoodThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/building-a-brotherhood.jpg';
import campaignProgressThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/campaign-progress.jpg';
import presidentsMessageThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/presidents-message.jpg';
import tasteOfBobaTeaSuccessThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/taste-of-boba-tea-success.jpg';
import touchdownOnTheRedPlanetThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/touchdown-on-the-red-planet.jpg';
import vanishingWordsThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/vanishing-words.jpg';
import workplaceLessonsFromThePandemicThumbnailSmall from '../../images/spring-summer-2021/small-thumbnails/workplace-lessons-from-the-pandemic.jpg';

import blindSopranoShootsForTheMoonIndex from '../../images/spring-summer-2021/index-thumbnails/blind-soprano-shoots-for-the-moon.jpg';
import buildingABrotherhoodIndex from '../../images/spring-summer-2021/index-thumbnails/building-a-brotherhood.jpg';
import campaignProgressIndex from '../../images/spring-summer-2021/index-thumbnails/campaign-progress.jpg';
import presidentsMessageIndex from '../../images/spring-summer-2021/index-thumbnails/presidents-message.jpg';
import tasteOfBobaTeaSuccessIndex from '../../images/spring-summer-2021/index-thumbnails/taste-of-boba-tea-success.jpg';
import touchdownOnTheRedPlanetIndex from '../../images/spring-summer-2021/index-thumbnails/touchdown-on-the-red-planet.jpg';
import vanishingWordsIndex from '../../images/spring-summer-2021/index-thumbnails/vanishing-words.jpg';
import workplaceLessonsFromThePandemicIndex from '../../images/spring-summer-2021/index-thumbnails/workplace-lessons-from-the-pandemic.jpg';

import blindSopranoShootsForTheMoonSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/blind-soprano-shoots-for-the-moon.jpg';
import buildingABrotherhoodSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/building-a-brotherhood.jpg';
import campaignProgressSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/campaign-progress.jpg';
import presidentsMessageSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/presidents-message.jpg';
import tasteOfBobaTeaSuccessSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/taste-of-boba-tea-success.jpg';
import touchdownOnTheRedPlanetSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/touchdown-on-the-red-planet.jpg';
import vanishingWordsSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/vanishing-words.jpg';
import workplaceLessonsFromThePandemicSmallRect from '../../images/spring-summer-2021/small-thumbnails-rect/workplace-lessons-from-the-pandemic.jpg';


export const spring2021stories = [
  {
    // MSI
    slug: 'building-a-brotherhood',
    title: 'Building a Brotherhood',
    subtitle: 'Through the Male Success Initiative-Fullerton, two brothers found academic and emotional support to prepare for careers in medicine.',
    storyType: 'feature',
    thumbnail: buildingABrotherhoodThumbnail,
    thumbnailSmall: buildingABrotherhoodThumbnailSmall,
    thumbnailSmallRect: buildingABrotherhoodSmallRect,
    thumbnailIndex: buildingABrotherhoodIndex,
  },
  {
    // Mars
    slug: 'touchdown-on-the-red-planet',
    title: 'Touchdown on the Red Planet',
    subtitle: 'Mechanical engineering alumna describes ‘seven minutes of terror’ before historic Mars 2020 landing.',
    storyType: 'feature',
    thumbnail: touchdownOnTheRedPlanetThumbnail,
    thumbnailSmall: touchdownOnTheRedPlanetThumbnailSmall,
    thumbnailSmallRect: touchdownOnTheRedPlanetSmallRect,
    thumbnailIndex: touchdownOnTheRedPlanetIndex,
  },
  {
    // Blind Soprano
    slug: 'blind-soprano-shoots-for-the-moon',
    title: '‘Blind Soprano’ Shoots for the Moon',
    subtitle: 'A golden-voiced alumna advocates for artists of all abilities.',
    storyType: 'feature',
    thumbnail: blindSopranoShootsForTheMoonThumbnail,
    thumbnailSmall: blindSopranoShootsForTheMoonThumbnailSmall,
    thumbnailSmallRect: blindSopranoShootsForTheMoonSmallRect,
    thumbnailIndex: blindSopranoShootsForTheMoonIndex,
  },
  {
    // Endangered Languages
    slug: 'vanishing-words',
    title: 'Vanishing Words',
    subtitle: 'Linguistics faculty members work to reclaim diminishing languages around the world.',
    storyType: 'feature',
    thumbnail: vanishingWordsThumbnail,
    thumbnailSmall: vanishingWordsThumbnailSmall,
    thumbnailSmallRect: vanishingWordsSmallRect,
    thumbnailIndex: vanishingWordsIndex,
  },
  {
    // Workplace Lessons
    slug: 'workplace-lessons-from-the-pandemic',
    title: '8 Workplace Lessons From the Pandemic',
    subtitle: 'Business faculty share how the ‘black swan’ event is changing American enterprise.',
    storyType: 'feature',
    thumbnail: workplaceLessonsFromThePandemicThumbnail,
    thumbnailSmall: workplaceLessonsFromThePandemicThumbnailSmall,
    thumbnailSmallRect: workplaceLessonsFromThePandemicSmallRect,
    thumbnailIndex: workplaceLessonsFromThePandemicIndex,
  },
  {
    // Campaign Timelines
    slug: 'campaign-progress',
    title: 'Highlights From the First Year of ‘It Takes a Titan’',
    subtitle: 'From notable gifts to record-breaking days of giving, Cal State Fullerton celebrates the campaign’s momentum.',
    storyType: 'regular',
    thumbnail: campaignProgressThumbnail,
    thumbnailSmall: campaignProgressThumbnailSmall,
    thumbnailSmallRect: campaignProgressSmallRect,
    thumbnailIndex: campaignProgressIndex,
  },
  {
    // Q&A w/ Boba Guys
    slug: 'taste-of-boba-tea-success',
    title: 'A Taste of Boba Tea Success',
    subtitle: 'While in college, two friends capitalized on a boba craze. Twenty years later, their Tastea franchise has expanded to 23 locations.',
    storyType: 'regular',
    thumbnail: tasteOfBobaTeaSuccessThumbnail,
    thumbnailSmall: tasteOfBobaTeaSuccessThumbnailSmall,
    thumbnailSmallRect: tasteOfBobaTeaSuccessSmallRect,
    thumbnailIndex: tasteOfBobaTeaSuccessIndex,
  },
  {
    slug: 'presidents-message',
    title: 'President\'s Message',
    subtitle: '',
    storyType: 'regular',
    thumbnail: presidentsMessageThumbnail,
    thumbnailSmall: presidentsMessageThumbnailSmall,
    thumbnailSmallRect: presidentsMessageSmallRect,
    thumbnailIndex: presidentsMessageIndex,
  }
];