import transformingTheFuture_Thumbnail from '../../images/spring-summer-2022/menu-thumbnails/transforming-the-future.jpg';
import pillarOfHope_Thumbnail from '../../images/spring-summer-2022/menu-thumbnails/pillar-of-hope.jpg';
import visionAndVisionaries_Thumbnail from '../../images/spring-summer-2022/menu-thumbnails/vision-and-visionaries.jpg';
import leadingVoiceInRadio_Thumbnail from '../../images/spring-summer-2022/menu-thumbnails/leading-voice-in-radio.jpg';
import presidentsMessage_Thumbnail from '../../images/spring-summer-2022/menu-thumbnails/presidents-message.jpg';

import transformingTheFuture_SmallThumbnail from '../../images/spring-summer-2022/small-thumbnails/transforming-the-future.jpg';
import pillarOfHope_SmallThumbnail from '../../images/spring-summer-2022/small-thumbnails/pillar-of-hope.jpg';
import visionAndVisionaries_SmallThumbnail from '../../images/spring-summer-2022/small-thumbnails/vision-and-visionaries.jpg';
import leadingVoiceInRadio_SmallThumbnail from '../../images/spring-summer-2022/small-thumbnails/leading-voice-in-radio.jpg';
import presidentsMessage_SmallThumbnail from '../../images/spring-summer-2022/small-thumbnails/presidents-message.jpg';

import transformingTheFuture_IndexThumbnail from '../../images/spring-summer-2022/index-thumbnails/transforming-the-future.jpg';
import pillarOfHope_IndexThumbnail from '../../images/spring-summer-2022/index-thumbnails/pillar-of-hope.jpg';
import visionAndVisionaries_IndexThumbnail from '../../images/spring-summer-2022/index-thumbnails/vision-and-visionaries.jpg';
import leadingVoiceInRadio_IndexThumbnail from '../../images/spring-summer-2022/index-thumbnails/leading-voice-in-radio.jpg';
import presidentsMessage_IndexThumbnail from '../../images/spring-summer-2022/index-thumbnails/presidents-message.jpg';

import transformingTheFuture_SmallRectThumbnail from '../../images/spring-summer-2022/small-thumbnails-rect/transforming-the-future.jpg';
import pillarOfHope_SmallRectThumbnail from '../../images/spring-summer-2022/small-thumbnails-rect/pillar-of-hope.jpg';
import visionAndVisionaries_SmallRectThumbnail from '../../images/spring-summer-2022/small-thumbnails-rect/vision-and-visionaries.jpg';
import leadingVoiceInRadio_SmallRectThumbnail from '../../images/spring-summer-2022/small-thumbnails-rect/leading-voice-in-radio.jpg';
import presidentsMessage_SmallRectThumbnail from '../../images/spring-summer-2022/small-thumbnails-rect/presidents-message.jpg';




export const springSummer2022Stories = [
  // First Gen
  {
    slug: 'transforming-the-future',
    title: 'Transforming the Future',
    subtitle: 'First-Generation Students Set the Path for Success',
    storyType: 'feature',
    thumbnail: transformingTheFuture_Thumbnail,
    thumbnailSmall: transformingTheFuture_SmallThumbnail,
    thumbnailSmallRect: transformingTheFuture_SmallRectThumbnail,
    thumbnailIndex: transformingTheFuture_IndexThumbnail,
  },
  // Veteran
  {
    slug: 'pillar-of-hope',
    title: 'A Pillar of Hope',
    subtitle: 'Military-connected students accomplish their personal and professional goals thanks to academic and emotional support from the Veterans Resource Center.',
    storyType: 'feature',
    thumbnail: pillarOfHope_Thumbnail,
    thumbnailSmall: pillarOfHope_SmallThumbnail,
    thumbnailSmallRect: pillarOfHope_SmallRectThumbnail,
    thumbnailIndex: pillarOfHope_IndexThumbnail,
  },
  // V&V
  {
    slug: 'vision-and-visionaries',
    title: 'Vision & Visionaries Celebrates 25th Ceremony and Dozens of Honorees',
    subtitle: null,
    storyType: 'feature',
    thumbnail: visionAndVisionaries_Thumbnail,
    thumbnailSmall: visionAndVisionaries_SmallThumbnail,
    thumbnailSmallRect: visionAndVisionaries_SmallRectThumbnail,
    thumbnailIndex: visionAndVisionaries_IndexThumbnail,
  },
  // Comouche
  {
    slug: 'leading-voice-in-radio',
    title: 'A Leading Voice in L.A. Radio',
    subtitle: 'A communications alumnus discusses his journey and career as co-host of one of the region’s most popular morning shows.',
    storyType: 'feature',
    thumbnail: leadingVoiceInRadio_Thumbnail,
    thumbnailSmall: leadingVoiceInRadio_SmallThumbnail,
    thumbnailSmallRect: leadingVoiceInRadio_SmallRectThumbnail,
    thumbnailIndex: leadingVoiceInRadio_IndexThumbnail,
  },
  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: presidentsMessage_Thumbnail,
    thumbnailSmall: presidentsMessage_SmallThumbnail,
    thumbnailSmallRect: presidentsMessage_SmallRectThumbnail,
    thumbnailIndex: presidentsMessage_IndexThumbnail,
  }
];