// import exampleStory_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/example-story.jpg';
// import exampleStory_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/example-story.jpg';

import criminalminds_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story1-menu-thumbnails-400x400.jpg';
import first100days_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story2-menu-thumbnails-400x400.jpg';
import northgate_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story3-menu-thumbnails-400x400.jpg';
import iracing_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story4-menu-thumbnails-400x400.jpg';
import nursing_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story5-menu-thumbnails-400x400.jpg';
import presidents_Thumbnail from '../../images/fall-winter-2024/menu-thumbnails/story6-menu-thumbnails-400x400.jpg';

import criminalminds_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story1-small-thumbnails-100x100.jpg';
import first100days_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story2-small-thumbnails-100x100.jpg';
import northgate_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story3-small-thumbnails-100x100.jpg';
import iracing_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story4-small-thumbnails-100x100.jpg';
import nursing_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story5-small-thumbnails-100x100.jpg';
import presidents_SmallThumbnail from '../../images/fall-winter-2024/small-thumbnails/story6-small-thumbnails-100x100.jpg';

import criminalminds_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story1-index-thumbnails-1920x1080.jpg';
import first100days_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story2-index-thumbnails-1920x1080.jpg';
import northgate_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story3-index-thumbnails-1920x1080.jpg';
import iracing_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story4-index-thumbnails-1920x1080.jpg';
import nursing_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story5-index-thumbnails-1920x1080.jpg';
import presidents_IndexThumbnail from '../../images/fall-winter-2024/index-thumbnails/story6-index-thumbnails-1920x1080.jpg';

import criminalminds_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story1-thumbnails-rect-250x125.jpg';
import first100days_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story2-thumbnails-rect-250x125.jpg';
import northgate_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story3-thumbnails-rect-250x125.jpg';
import iracing_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story4-thumbnails-rect-250x125.jpg';
import nursing_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story5-thumbnails-rect-250x125.jpg';
import presidents_SmallRectThumbnail from '../../images/fall-winter-2024/small-thumbnails-rect/story6-thumbnails-rect-250x125.jpg';

import ClassNotes_Thumbnail from '../../images/fall-winter-2023/class-notes.jpg';
import ClassNotes_IndexThumbnail from '../../images/fall-winter-2023/class-notes-banner.jpg';

// import ClassNotes_Thumbnail from '../../images/fall-winter-2023/class-notes.jpg';
// import ClassNotes_IndexThumbnail from '../../images/fall-winter-2023/class-notes-banner.jpg';

// export const fallSummer2024Stories = [
//   {
//     slug: 'example-story',
//     title: 'Cillum occaecat nisi elit ut.',
//     subtitle: 'Ullamco ipsum sunt consectetur elit eu non amet dolor reprehenderit incididunt. Nostrud elit incididunt ad enim. Duis labore cillum laborum ullamco.',
//     storyType: 'feature',
//     thumbnail: exampleStory_Thumbnail,
//     thumbnailSmall: exampleStory_Thumbnail,
//     thumbnailSmallRect: exampleStory_Thumbnail,
//     thumbnailIndex: exampleStory_IndexThumbnail,
//     homepageTextPosition: 'left'
//   },

// ];

export const fallWinter2024Stories = [
  {
    slug: 'cracking-the-code-on-criminal-minds',
    title: 'Cracking the Code on ‘Criminal Minds’',
    subtitle: 'Theatre arts alum Kirsten Vangsness plays Penelope Garcia in the hit CBS show and Paramount+ spinoff.',
    storyType: 'feature',
    thumbnail: criminalminds_Thumbnail,
    thumbnailSmall: criminalminds_SmallThumbnail,
    thumbnailSmallRect: criminalminds_SmallRectThumbnail,
    thumbnailIndex: criminalminds_IndexThumbnail,
    homepageTextPosition: 'right'
  },



  {
    slug: 'first-100-days',
    title: 'Leading Fullerton Forward',
    subtitle: 'During his first 100 days, Cal State Fullerton President Ronald S. Rochon engaged with Titans across the campus and shared his commitment to delivering an exceptional education to each student.',
    storyType: 'feature',
    thumbnail: first100days_Thumbnail,
    thumbnailSmall: first100days_SmallThumbnail,
    thumbnailSmallRect: first100days_SmallRectThumbnail,
    thumbnailIndex: first100days_IndexThumbnail,
    homepageTextPosition: 'left',
    external: 'TRUE',
    url: 'https://president.fullerton.edu/100days/',
    buttonText: 'Visit First 100 Days Website',
  },


  {
    slug: 'titan-builds-on-northgate-markets-success',
    title: 'Titan Builds on Northgate Market’s Success',
    subtitle: 'Business alum Joshua González is a leader in the family-owned Mexican supermarket chain.',
    storyType: 'feature',
    thumbnail: northgate_Thumbnail,
    thumbnailSmall: northgate_SmallThumbnail,
    thumbnailSmallRect: northgate_SmallRectThumbnail,
    thumbnailIndex: northgate_IndexThumbnail,
    homepageTextPosition: 'right'
  },

  {
    slug: 'a-champion-on-the-iracing-circuit',
    title: 'A Champion on the iRacing Circuit',
    subtitle: 'Titan Esports simulation racer Logan Clampitt is known on virtual racing circuits across the globe. ',
    storyType: 'feature',
    thumbnail: iracing_Thumbnail,
    thumbnailSmall: iracing_SmallThumbnail,
    thumbnailSmallRect: iracing_SmallRectThumbnail,
    thumbnailIndex: iracing_IndexThumbnail,
    homepageTextPosition: 'right'
  },

  {
    slug: 'how-the-pandemic-changed-nursing-and-me',
    title: "How the Pandemic Changed Nursing … and Me",
    subtitle: 'During the darkest days of the COVID-19 pandemic, nursing faculty member Christine Kaford leaned into gratitude and hope. She teaches students to do the same.',
    storyType: 'feature',
    thumbnail: nursing_Thumbnail,
    thumbnailSmall: nursing_SmallThumbnail,
    thumbnailSmallRect: nursing_SmallRectThumbnail,
    thumbnailIndex: nursing_IndexThumbnail,
    homepageTextPosition: 'right'
  },

  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: 'Caption Needed',
    storyType: 'feature',
    thumbnail: presidents_Thumbnail,
    thumbnailSmall: presidents_SmallThumbnail,
    thumbnailSmallRect: presidents_SmallRectThumbnail,
    thumbnailIndex: presidents_IndexThumbnail,
    homepageTextPosition: 'left'
  },

  {
    slug: 'class-notes',
    title: 'Alumni Class Notes',
    subtitle: 'Class Notes is the place to catch up. Let fellow alumni know about career moves, awards and honors, publications and performances, births and marriages, and other life events.',
    storyType: 'feature',
    thumbnail: ClassNotes_Thumbnail,
    thumbnailSmall: ClassNotes_Thumbnail,
    thumbnailSmallRect: ClassNotes_IndexThumbnail,
    thumbnailIndex: ClassNotes_IndexThumbnail,
    homepageTextPosition: 'left',
    external: 'TRUE',
    url: 'https://alumni.fullerton.edu/class-notes/',
    buttonText: 'Visit Class Notes',
  },

];