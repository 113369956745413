import underTheBigTop_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/under-the-big-top.jpg';
import californiaCondors_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/california-condors.jpg';
import edgeOfTheUniverse_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/edge-of-the-universe.jpg';
import unbreakableZackCollie_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/unbreakable-zack-collie.jpg';
import championsOfCoaching_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/champions-of-coaching.jpg';
import campaignHighlights_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/campaign-highlights.jpg';
import presidentsMessage_Thumbnail from '../../images/fall-winter-2022/menu-thumbnails/presidents-message.jpg';

import underTheBigTop_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/under-the-big-top.jpg';
import californiaCondors_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/california-condors.jpg';
import edgeOfTheUniverse_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/edge-of-the-universe.jpg';
import unbreakableZackCollie_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/unbreakable-zack-collie.jpg';
import championsOfCoaching_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/champions-of-coaching.jpg';
import campaignHighlights_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/campaign-highlights.jpg';
import presidentsMessage_SmallThumbnail from '../../images/fall-winter-2022/small-thumbnails/presidents-message.jpg';

import underTheBigTop_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/under-the-big-top.jpg';
import californiaCondors_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/california-condors.jpg';
import edgeOfTheUniverse_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/edge-of-the-universe.jpg';
import unbreakableZackCollie_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/unbreakable-zack-collie.jpg';
import championsOfCoaching_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/champions-of-coaching.jpg';
import campaignHighlights_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/campaign-highlights.jpg';
import presidentsMessage_IndexThumbnail from '../../images/fall-winter-2022/index-thumbnails/presidents-message.jpg';

import underTheBigTop_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/under-the-big-top.jpg';
import californiaCondors_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/california-condors.jpg';
import edgeOfTheUniverse_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/edge-of-the-universe.jpg';
import unbreakableZackCollie_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/unbreakable-zack-collie.jpg';
import championsOfCoaching_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/champions-of-coaching.jpg';
import campaignHighlights_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/campaign-highlights.jpg';
import presidentsMessage_SmallRectThumbnail from '../../images/fall-winter-2022/small-thumbnails-rect/presidents-message.jpg';




export const fallWinter2022Stories = [
  // Cirque du Soleil
  {
    slug: 'under-the-big-top',
    title: 'Under the Big Top',
    subtitle: 'A sound engineer, automation technician and makeup artist are among the Cal State Fullerton alumni who have worked with Cirque du Soleil, the largest contemporary circus producer in the world.',
    storyType: 'feature',
    thumbnail: underTheBigTop_Thumbnail,
    thumbnailSmall: underTheBigTop_SmallThumbnail,
    thumbnailSmallRect: underTheBigTop_SmallRectThumbnail,
    thumbnailIndex: underTheBigTop_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  // CA Condors
  {
    slug: 'california-condors',
    title: 'Safe Passage for California Condors',
    subtitle: 'A Cal State Fullerton alumna sets her sights on protecting critically endangered species like the California condor.',
    storyType: 'feature',
    thumbnail: californiaCondors_Thumbnail,
    thumbnailSmall: californiaCondors_SmallThumbnail,
    thumbnailSmallRect: californiaCondors_SmallRectThumbnail,
    thumbnailIndex: californiaCondors_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  // Cosmic Explorer
  {
    slug: 'edge-of-the-universe',
    title: 'To the Edge of the Universe — and Beyond',
    subtitle: 'With the next-generation Cosmic Explorer observatory, Cal State Fullerton gravitational-wave scientists shift research focus toward future discoveries of the cosmos.',
    storyType: 'feature',
    thumbnail: edgeOfTheUniverse_Thumbnail,
    thumbnailSmall: edgeOfTheUniverse_SmallThumbnail,
    thumbnailSmallRect: edgeOfTheUniverse_SmallRectThumbnail,
    thumbnailIndex: edgeOfTheUniverse_IndexThumbnail,
    homepageTextPosition: 'right'
  },
  // Zack Collie
  {
    slug: 'unbreakable-zack-collie',
    title: 'The Unbreakable Zack Collie',
    subtitle: 'He broke his neck, but it didn’t break him. Counseling graduate student Zack Collie hopes to help other survivors of traumatic events find a path forward.',
    storyType: 'feature',
    thumbnail: unbreakableZackCollie_Thumbnail,
    thumbnailSmall: unbreakableZackCollie_SmallThumbnail,
    thumbnailSmallRect: unbreakableZackCollie_SmallRectThumbnail,
    thumbnailIndex: unbreakableZackCollie_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  // Champions of Coaching
  {
    slug: 'champions-of-coaching',
    title: 'Champions of Coaching',
    subtitle: 'Basketball coaches Anthony Darmiento and Ramsey Nijem share how their CSUF education propelled them to championship titles.',
    storyType: 'feature',
    thumbnail: championsOfCoaching_Thumbnail,
    thumbnailSmall: championsOfCoaching_SmallThumbnail,
    thumbnailSmallRect: championsOfCoaching_SmallRectThumbnail,
    thumbnailIndex: championsOfCoaching_IndexThumbnail,
    homepageTextPosition: 'left'
  },
  // Campaign
  {
    slug: 'campaign-highlights',
    title: '‘It Takes a Titan’ Campaign Changes Lives',
    subtitle: 'A collection of philanthropic stories showcasing how Cal State Fullerton supporters generously answered the call to empower students to reach their highest potential.',
    storyType: 'feature',
    thumbnail: campaignHighlights_Thumbnail,
    thumbnailSmall: campaignHighlights_SmallThumbnail,
    thumbnailSmallRect: campaignHighlights_SmallRectThumbnail,
    thumbnailIndex: campaignHighlights_IndexThumbnail,
    homepageTextPosition: 'center'
  },
  {
    slug: 'presidents-message',
    title: 'President’s Message',
    subtitle: null,
    storyType: 'feature',
    thumbnail: presidentsMessage_Thumbnail,
    thumbnailSmall: presidentsMessage_SmallThumbnail,
    thumbnailSmallRect: presidentsMessage_SmallRectThumbnail,
    thumbnailIndex: presidentsMessage_IndexThumbnail,
    homepageTextPosition: 'left'
  }
];